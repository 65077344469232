import React from "react"
import styled from "styled-components"
import RichText from "../richText"

const SliceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  flex-direction: ${p => (p.alignLeft ? "row-reverse" : "row")};

  a {
    color: ${p => p.theme.color.green.dark};
    box-shadow: 0px 0px 0px 0px ${p => p.theme.color.green.dark};
    transition: all .3s ease-in-out;
    font-weight: bold;

    :hover {
      border-radius: ${p => p.theme.radius.small};
      box-shadow: 0px 4px 0px 0px ${p => p.theme.color.green.normal};
      background-color: ${p => p.theme.color.green.lighter};
      color: ${p => p.theme.color.black};
    }
  }

  .img {
    max-width: 400px;
  }

  @media (min-width: 600px) {
    .img {
      padding: 10px;
      width: 40%;
    }

    .text {
      width: 60%;
    }
  }
`

const TextAndImageBlock = ({ featuredImage, imageAlign, content }) => {
  return (
    <SliceWrapper alignLeft={imageAlign}>
      <div className="text">
        <RichText render={content} />
      </div>

      <div className="img">
        <img
          src={featuredImage.url}
          loading="lazy"
          alt={
            featuredImage.alt && !null
              ? featuredImage.alt
              : "Ein Bild das das Thema veranschaulicht"
          }
        />
      </div>
    </SliceWrapper>
  )
}

export default TextAndImageBlock
