import React from "react"
import styled from "styled-components"
import RichText from "../richText"
import Button from "./Button"
import { Link } from "gatsby"

const CardWrapper = styled.div`
  min-width: calc(100% - 30px);
  background-color: ${p => p.theme.color.white};
  border-radius: ${p => p.theme.radius.normal};

  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  box-shadow: 0px 7px 15px rgba(130, 130, 130, 0.5);

  position: relative;

  .card-img {
    max-height: 220px;
    width: 100%;
    border-top-left-radius: ${p => p.theme.radius.normal};
    border-top-right-radius: ${p => p.theme.radius.normal};

    overflow: hidden;

    object-fit: cover;
  }

  .inner-wrapper {
    padding: 20px 20px 60px 20px;
    .link a {
      color: ${p => p.theme.color.green.dark};
      box-shadow: 0px 0px 0px 0px ${p => p.theme.color.green.dark};
      transition: all 0.3s ease-in-out;
      font-weight: bold;

      :hover {
        border-radius: ${p => p.theme.radius.small};
        box-shadow: 0px 4px 0px 0px ${p => p.theme.color.green.normal};
        background-color: ${p => p.theme.color.green.lighter};
        color: ${p => p.theme.color.black};
      }
    }
  }

  .button-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
  }

  .custom-button {
  }

  @media (min-width: 451px) {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;

    min-width: 100px;
    max-width: 380px;
  }
`


const Card = ({
  title,
  content,
  buttonLabel,
  buttonDestination,
  featuredImage,
  imageAlt,
}) => {
  return (
    <CardWrapper>
      <div className="card-img">
        <img
          src={featuredImage}
          loading="lazy"
          alt={
            imageAlt && !null
              ? imageAlt
              : "Ein Bild das dieses Thema veranschaulicht"
          }
        />
      </div>
      <div className="inner-wrapper">
        <RichText render={title} />
        <div className="link"><RichText render={content} /></div>
        <div className="button-wrapper">
          <Button className="custom-button">
            <Link to={buttonDestination}>{buttonLabel}</Link>
          </Button>
        </div>
      </div>
    </CardWrapper>
  )
}

export default Card
