import React from "react"
import Layout from "../components/layout"
import SlizeZone from "../components/sliceZone"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"


export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            page_description
            page_title
            _linkType
            body {
              ... on PRISMIC_HomepageBodyHero {
                type
                primary {
                  hero_content
                  hero_title
                  background_image
                }
                fields {
                  cta_style
                  cta__label
                  cta_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyCall_to_action_grid {
                type
                label
                primary {
                  section_title
                }
                fields {
                  content
                  button_label
                  cta_title
                  featured_image
                  button_destenation {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyRich_text {
                type
                label
                primary {
                  rich_text_content
                  text_align
                }
              }
              ... on PRISMIC_HomepageBodyText_and_image {
                type
                primary {
                  title
                }
                fields {
                  image
                  image_align
                  text
                }
              }
              ... on PRISMIC_HomepageBodyVideo {
                type
                primary {
                  title
                  video_embed
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const prismicConetent = props.data.prismic.allHomepages.edges[0]
  if (!prismicConetent) return null

  const document = prismicConetent.node

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{document.page_title}</title>
        <meta name="description" content={document.page_description} />
      </Helmet>
      <Layout>
        <SlizeZone body={document.body} />
      </Layout>
    </>
  )
}

export default IndexPage
