import React from "react"
import styled from "styled-components"
import RichText from "../richText"
import TextAndImageBlock from "./TextAndImageBlock"

const SliceWrapper = styled.section`
  width: 100%;
  max-width: 800px;
  padding: 10px;
  margin: 10px auto;
`

const TextAndImage = ({ title, textAndImageBlocks }) => {

  return (
    <SliceWrapper>
      <RichText render={title} />
      {textAndImageBlocks.map(({ image, image_align, text }, i) => {
        return (
          <TextAndImageBlock
            key={i}
            featuredImage={image}
            imageAlign={image_align==="left"}
            content={text}
          />
        )
      })}
    </SliceWrapper>
  )
}

export default TextAndImage
