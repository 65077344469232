import React from "react"
import styled from "styled-components"
import RichText from "../richText"
import Card from "./card"

const CallToActionGridWrapper = styled.section`
  max-width: 100%;
  margin: 10px auto;
`

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  position: relative;


  @media (min-width: 451px) {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;

    width: 100%;
  }
`

const Title = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;


  @media (min-width: 451px) {
    text-align: center;
  }
`

const CallToActionGrid = ({ title, callToActions }) => {
  return (
    <CallToActionGridWrapper>
      <Title>
        <RichText render={title} />
      </Title>
      <CardsWrapper>
        {callToActions.map((callToAction, i) => {
          return (
            <Card
              featuredImage={callToAction.featured_image.url}
              imageAlt={callToAction.featured_image.alt}
              key={i}
              buttonLabel={callToAction.button_label}
              buttonDestination={`/${callToAction.button_destenation._meta.uid}`}
              title={callToAction.cta_title}
              content={callToAction.content}

            />
          )
        })}
      </CardsWrapper>
    </CallToActionGridWrapper>
  )
}

export default CallToActionGrid
