import React from "react"
import RichText from "../richText"
import Button from "./Button"
import styled from "styled-components"
import { Link } from "gatsby"

const HeroWrapper = styled.section`
  height: auto;
  width: 100%;
  background:url('${props => props.backgroundImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

`

const ContentWrapper = styled.div`
  max-width: 800px;
  min-height: 45vh;
  margin: 0 auto;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(100%, 100%, 100%, 90%);

  -webkit-box-shadow: 0px 0px 150px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 150px -7px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 150px -7px rgba(255, 255, 255, 1);

  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(7px);
  }
`

const TextWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: ${p => p.theme.fontSize.normal};
  }

  color: ${p => p.theme.color.black};
`

const CtaWrapper = styled.div`
  width: 100%;

  margin-top: auto;

  @media (min-width: 451px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const ButtonContainer = styled.div`
  padding: 0 10px;
  @media (min-width: 451px) {
    width: calc(50% - 20px);
    padding: 0;

    margin: 0 10px;
  }
`

const Hero = ({ title, content, ctaGroup, backgroundImage }) => {
  return (
    <HeroWrapper backgroundImage={backgroundImage}>
      <ContentWrapper>
        <TextWrapper>
          <RichText render={title} />
          <p>{content}</p>
        </TextWrapper>

        <CtaWrapper>
          {ctaGroup.map(({ cta__label, cta_link, cta_style }, i) => {
            return (
              <ButtonContainer>
                <Button
                  key={i}
                  secondary={cta_style === "secondary"}
                  tertiary={cta_style === "tertiary"}
                >
                  <Link to={`/${cta_link._meta.uid}`}>{cta__label}</Link>
                </Button>
              </ButtonContainer>
            )
          })}
        </CtaWrapper>
      </ContentWrapper>
    </HeroWrapper>
  )
}

export default Hero
