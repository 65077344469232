import styled from "styled-components"
import React from "react"
import RichText from "../richText"

const SliceWrapper = styled.section`
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
  padding: 10px;
  text-align: ${p => (p.textAlign === "center" ? "center" : "left")};

  a {
    color: ${p => p.theme.color.green.dark};
    box-shadow: 0px 0px 0px 0px ${p => p.theme.color.green.dark};
    transition: all .3s ease-in-out;
    font-weight: bold;

    :hover {
      border-radius: ${p => p.theme.radius.small};
      box-shadow: 0px 4px 0px 0px ${p => p.theme.color.green.normal};
      background-color: ${p => p.theme.color.green.lighter};
      color: ${p => p.theme.color.black};
    }
  }
`

const TextSlice = ({ content }) => {
  return (
    <SliceWrapper>
      <RichText render={content} />
    </SliceWrapper>
  )
}

export default TextSlice
