import React from "react"
import styled from "styled-components"
import RichText from "../richText"

const SliceWrapper = styled.section`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
`
const Video = styled.div`
  box-shadow: 0px 7px 15px rgba(130, 130, 130, 0.5);
  border-radius: ${p => p.theme.radius.normal};
  overflow: hidden;
  > div {
    padding: 56.25% 0 0 0;
    position: relative;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const VideoEmbed = ({ videoData, title }) => {
  return (
    <SliceWrapper>
      <RichText render={title} />
      <Video>
        <div dangerouslySetInnerHTML={{ __html: videoData.html }}></div>
      </Video>
    </SliceWrapper>
  )
}

export default VideoEmbed
