import React from "react"
import Hero from "./common/hero"
import TextSlice from "./common/TextSlice"
import TextAndImageGroup from "./common/TextAndImageGroup"
import CallToActionGrid from "./common/callToActionGrid"
import VideoEmbed from "./common/VideoEmbed"

const SlizeZone = ({ body }) => {
  return (
    <div>
      {body.map((bodyContent, i) => {
        if (bodyContent.type === "hero") {
          return (
            <Hero
              key={i}
              backgroundImage={bodyContent.primary.background_image.url}
              title={bodyContent.primary.hero_title}
              content={bodyContent.primary.hero_content}
              ctaGroup={bodyContent.fields}
            />
          )
        } else if (bodyContent.type === "call_to_action_grid") {
          return (
            <CallToActionGrid
              key={i}
              callToActions={bodyContent.fields}
              title={bodyContent.primary.section_title}
            />
          )
        } else if (bodyContent.type === "rich_text") {
          return (
            <TextSlice
              key={i}
              content={bodyContent.primary.rich_text_content}
              textAlign={bodyContent.primary.text_align}
            />
          )
        } else if (bodyContent.type === "text_and_image") {
          return (
            <TextAndImageGroup
              key={i}
              title={bodyContent.primary.title}
              textAndImageBlocks={bodyContent.fields}
            />
          )
        } else if (bodyContent.type === "video") {
          return (
            <VideoEmbed
              key={i}
              title={bodyContent.primary.title}
              videoData={bodyContent.primary.video_embed}
            />
          )
        } else {
          return <div></div>
        }
      })}
    </div>
  )
}

export default SlizeZone
